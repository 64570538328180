import classNames from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import { RoomContext } from "../context/RoomContext";

export const VideoPlayer: React.FC<{ videoWidth?:string, stream?:MediaStream, mute?:boolean, id:string ,orientation?:string , onClick?: () => void,degree?:number}> = ({videoWidth,stream,mute,id,orientation='horizontal',onClick,degree}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const {reinitializeStream} = useContext(RoomContext)

    useEffect(()=>{
          if(videoRef.current && stream ) {
            videoRef.current.srcObject = stream;
            videoRef.current.addEventListener('suspend', reinitializeStream);
        }
    },[stream,videoRef])

    useEffect(()=>{
        //if player stop stream need to change peerstream
        window.addEventListener('pause',(event)=>{
            console.log('stop')
        })
    },[])
    
    return (<>
        { videoWidth !== 'full' &&<video  className={classNames("h-full", {
            "w-full object-cover 2xl:h-58": orientation === 'horizontal',
            "w-48 object-contain": orientation === 'vertical'
          })} id={id} data-testid="peer-video" ref={videoRef} autoPlay muted={true} onClick={onClick} style={{rotate:`${degree}deg`}}/>}
        { videoWidth === 'full' &&<video  className={classNames("w-full", {
            "object-cover h-full": orientation === 'horizontal',
            "fixed object-contain h-2/3": orientation === 'vertical'
          })} id={id} data-testid="peer-video" ref={videoRef} autoPlay muted={true} onClick={onClick} style={{rotate:`${degree}deg`}}/>}
    </>)
}