import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams} from 'react-router-dom';
// import { Chat } from '../components/chat/Chat';
// import { ChatButton } from '../components/ChatButton';
// import { ShareScreenButton } from '../components/ShareScreenButton';
import { RoomContext } from '../context/RoomContext';
import { ws } from '../ws';
import { UserContext } from '../context/UserContext';
// import { ChatContext } from '../context/ChatContext';
import collect from 'collect.js';
// import { DisconnectButton } from '../components/DisconnectButton';
import { OptionButton } from '../components/OptionButton';
import { OptionSidebar } from '../components/OptionSidebar';
import classNames from 'classnames';
// import { CloseClassButton } from '../components/CloseClassButoon';
import { Alert } from '../components/Alert';
import { BackButton } from '../components/BackButton';
import { StudentView } from '../components/room/StudentView';
import { TeacherView } from '../components/room/TeacherView';
import { Tooltip } from '../components/common/Tooltip';
import { GoBackPortalButton } from '../components/GoBackPortalButton';
import { toast } from 'react-toastify';

export const Room = ()=>{
    const {id} = useParams()
    const {stream,screenStream,peers,shareScreen,screenSharingId,setRoomId,connectionLost,audioOutputDevices,audioInputDevices,toggleSidebar,optionSidebar,exitRoom,closeClass,classStatus,alertShow,alertNote,getAuth,isLogin,setAlertShow,setAlertNote,videoDevices,getDevices,setInitialVideoDevice,initializeStream,isReconnecting,setIsReconnecting} = useContext(RoomContext)
    const {userName,userId,role} = useContext(UserContext)
    // const {toggleChat, chats} = useContext(ChatContext)
    const [selectedAudioOutput,setAudioOutput] = useState<string>("");
    const [selectedAudioInput,setAudioInput] = useState<string>("");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    
    useEffect(()=>{
        if(role === "Student" && sessionStorage.getItem("student_restart") === "true"){
            toast.info('Reconnecting')
            setIsReconnecting(true)
        }
        if(id){
            ws.emit('check-room-id',{ roomId : id})
        }
        if(classStatus){
            if (token) {
                const tokenData:string = `Bearer ${token}` 
                getAuth(tokenData)
            }else{
                setAlertNote("Token Not Found!")
                setAlertShow(true)
            }
            if(role !== "Student"){
                ws.emit("join-room",{ roomId : id, peerId:userId, userName, role:role, mute:true })
            }
            if(role === "Student" && sessionStorage.getItem("student_restart") === "true"){
                initializeStream(true);
                sessionStorage.removeItem("student_restart")
            }
            else if(role === "Student"){
                initializeStream(false)
            }   
            if(stream){
                ws.emit("join-room",{ roomId : id, peerId:userId, userName, role:role, mute:true })
                const defaultAudioOutput:any = collect(audioOutputDevices).where('deviceId','default').first();
                if(defaultAudioOutput){
                    var outputLabel = defaultAudioOutput.label.split("-")[1].substring(1)
                    const defaultLabel:any = collect(audioOutputDevices).where('label',outputLabel).first()
                    if (defaultLabel) {
                        setAudioOutput(defaultLabel.deviceId)
                    } else {
                        // Handle case where defaultAudioOutput is undefined
                        console.log('No default audio output device found.');
                    }
                }
    
                const defaultAudioInput:any = collect(audioInputDevices).where('deviceId','default').first();
                if (defaultAudioInput){
                    var inputLabel = defaultAudioInput.label.split("-")[1].substring(1)
                    const defaultInputLabel:any = collect(audioInputDevices).where('label',inputLabel).first()
                    if (defaultInputLabel) {
                        setAudioInput(defaultInputLabel.deviceId)
                    } else {
                        // Handle case where defaultAudioOutput is undefined
                        console.log('No default audio output device found.');
                    }
                }  
                
            } 
        }
    },[id,ws,userId,stream,userName,role,token,classStatus])

    useEffect(()=>{
        setRoomId(id || "")
    },[id,setRoomId])
    const screenSharingVideo = (screenSharingId === userId) ? screenStream : peers[screenSharingId]?.stream;
    const {[screenSharingId]:sharing, ...peersToShow} = peers;

   
    if (isLogin && !isReconnecting) {
        return <>
            <div className="flex flex-col min-h-screen">
                <div className={classNames("p-4 bg-sky-500 text-white", {
                                            "bg-white": role === 'Student',
                                            "flex justify-between h-20": role !== 'Student'
                                          })}>
                    <div className={classNames("", {
                                            "hidden": role === 'Student',
                                            "flex": role !== 'Student'
                                          })}>Room id {id} </div>
                    <div className={classNames("flex absolute right-0 top-0 z-20", {
                                            "mt-5 mr-5": role === 'Student',
                                            "mt-3 mr-3": role !== 'Student'
                                          })}>
                        <Tooltip message={"Option"} > {(role === 'Student' && stream) && <OptionButton onClick={toggleSidebar} />}</Tooltip>
                        <OptionSidebar open={optionSidebar} onClick={toggleSidebar} defaultAudioOutput={selectedAudioOutput} defaultAudioInput={selectedAudioInput}/>
                    </div>
                    <div className='fixed z-20 left-0 bottom-0 w-screen flex justify-center pb-5'>
                        {/* {role === 'Teacher' && <CloseClassButton onClick={() => closeClass(id as string)} />} */}
                        {/* {role === 'Student' && <DisconnectButton onClick={() => exitRoom(id as string)} />} */}
                        <GoBackPortalButton />
                    </div>
                </div> 
                {(role === 'Student' && !stream) && 
                <div className="flex w-full justify-center items-center h-screen absolute">
                    <div className='bg-sky-400 bg-opacity-50 w-[500px] h-52 flex flex-col items-center justify-end pb-5 rounded-lg'>
                        <div className='text-white mb-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-white mr-2'>Select Camera :</span>
                            <select className='text-black border-2 border-white rounded p-2' onChange={(event) => setInitialVideoDevice(event.target.value)}>
                                {
                                    Object.values(videoDevices as object).map((item) => (
                                        <option value={item.deviceId} key={item.deviceId}>{item.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='flex mt-4'>
                            <Tooltip message={"Refresh"} > 
                                <button className='text-white px-2 py-1 rounded-md border-2 border-white mr-2 hover:bg-gray-50 hover:text-sky-400' onClick={()=>getDevices(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                </button>
                            </Tooltip>
                            <Tooltip message={"Join"} > 
                                <button className='text-white px-2 py-1 bg-sky-400 rounded-md border-2 border-white hover:bg-gray-50 hover:text-sky-400' onClick={()=>initializeStream(true)}>
                                    JOIN CLASS
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                }
                <div className={classNames("", {
                                            "hidden": !stream,
                                            "flex": stream })}>
                    {role === 'Student' && <StudentView videoSource={stream} peerArray={peersToShow} userName={userName}/>}
                </div>
                    {role === 'Teacher' && <TeacherView videoSource={stream} peerArray={peersToShow} userName={userName} roomId={id as string}/>}
                    {role === 'Admin' && <TeacherView videoSource={stream} peerArray={peersToShow} userName={userName} roomId={id as string}/>}
            </div>
        </>
    }

    return <>
        <div className='fixed right-0 m-2'>
            <Alert open={alertShow} note={alertNote}/>
        </div>

        <div className='fixed left-0 m-2'>
            <BackButton />
            
            {isReconnecting}
        </div>
            
        {(!alertShow && isReconnecting) && (<div className="flex flex-col min-h-screen items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-sky-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg> 
        </div>)} 
    </>
}